<script setup lang="ts">
import type { PaymentsListItemResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import { PaymentErrorCategory, STATES_UI_PAYMENT } from '~/types/bookingPayment'
import type { AvailablePaymentType, PaymentCardModel, PaymentsFormsNew } from '~/types/bookingPayment'
import type { AllNullable, AppFunnelStep } from '~/types/commons'
import { YscError } from '~/utils/error/YscError'
import { YSC_API_BOOKING_GUEST_ERROR, YSC_API_PAYMENT_SUMMARY_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  currentStep: AppFunnelStep
  idBooking: number
  paymentCallbackHref: string
  backUrlHref: string
}

const props = defineProps<Props>()

const loadingModel = defineModel<boolean>('loading', { default: false })

const emit = defineEmits<{
  submitted: [{ transactionId?: number }]
}>()

const route = useRoute()
const safeRouteQueryErrorCode = computed(() => {
  const errorCode = queryValueToString(route.query.errorCode)
  if (!isDefined(errorCode)) {
    return undefined
  }

  // For some case considered as error by the API we don't want to show the error page but just redirect to the payment form
  // for example with 3rd party solution such as Paypal, clicking the "Go back to yescapa" link shouldn't display an error page.
  const redirectToPaymentCodes: string[] = [
    '201004', // Paypal - PayPal account's owner has not approved payment
  ]

  if (redirectToPaymentCodes.includes(errorCode)) {
    return undefined
  }

  return errorCode
})

const openErrorModal = async ({ errorCode }: { errorCode: string | number }) => {
  await navigateTo({
    query: {
      errorCode: errorCode,
    },
  })
}

const { getReferentialMaps } = useReferentialStore()
const currencyMap = getReferentialMaps('currency')

// payment methods
const flagPaymentPaypal = useFeatureFlag('paymentPaypal')
const flagPaymentMbway = useFeatureFlag('paymentMbway')
const flagPaymentIdeal = useFeatureFlag('paymentIdeal')

const checkoutStore = useCheckoutStore()
const { $api } = useYscApi()
const { withErrorManagerHandling } = useWithErrorManagerHandling()
const { error, data: bookingPaymentMethods, status } = await useAsyncData(`booking-${props.idBooking}-payment-methods`, async () => {
  const bookingPaymentMethods = await withErrorManagerHandling(
    () => ($api.requests.getPayment(props.idBooking)) as unknown as PaymentsListItemResponse[],
    YSC_API_BOOKING_GUEST_ERROR,
    true,
  )

  // can't parralellise calls because of API side effect on prestations
  await withErrorManagerHandling(
    () => checkoutStore.fetchCheckoutInfos({ id: props.idBooking }),
    YSC_API_PAYMENT_SUMMARY_ERROR,
    true,
  )
  return bookingPaymentMethods
}, {
  lazy: true,
})

const { isPending } = useIsPending(status)

if (error.value && !isPending.value) {
  await openErrorModal({ errorCode: PaymentErrorCategory.YSC_PAYMENTS })
}

const bookingPaymentMethodsSet = computed(() => {
  if (!isDefined(bookingPaymentMethods.value)) {
    return new Set()
  }
  return new Set(bookingPaymentMethods.value.map(({ payment_type }) => payment_type))
})

const cardFormFactory = (): AllNullable<PaymentCardModel> => ({
  cardNumber: null,
  expirationDateMonth: null,
  expirationDateYear: null,
  ccv: null,
})

const bookingPaymentForm = ref<{
  selectedMethod: AvailablePaymentType | null
  forms: PaymentsFormsNew
}>({
  selectedMethod: null,
  forms: {
    [BOOKING_PAYMENT_METHODS.CREDIT_CARD]: cardFormFactory(),
    [BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x]: cardFormFactory(),
    [BOOKING_PAYMENT_METHODS.IDEAL]: { bic: null },
    [BOOKING_PAYMENT_METHODS.MBWAY]: { phone: null },
    [BOOKING_PAYMENT_METHODS.KLARNA]: {},
    [BOOKING_PAYMENT_METHODS.PAYPAL]: {},
    [BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x]: {},
  },
})

const uistate = ref<STATES_UI_PAYMENT>()
const transactionId = ref<number>()

const onExecutePaymentError = async (e: unknown) => {
  loadingModel.value = false
  let errorCode: string = PaymentErrorCategory.YSC_PAYMENTS
  if (e instanceof YscError) {
    errorCode = e.fingerprint
  }
  else if (e instanceof Error && 'code' in e) {
    errorCode = `${e.code}`
  }
  await openErrorModal({ errorCode })
}

// card payment
const {
  executePayment: executePaymentCard,
} = useExecutePaymentCard({ idBooking: props.idBooking })

// MB Way payment
const mbWayPaymentId = ref<number>()
const { t } = useI18n()
const mbWayProcessingTitle = computed(() => {
  switch (uistate.value) {
    case STATES_UI_PAYMENT.MB_WAY_EXECUTION: {
      return t('pages.checkout.async.created.title')
    }
    case STATES_UI_PAYMENT.MB_WAY_FINALIZATION : {
      return t('pages.checkout.async.waiting.title')
    }
    default: return undefined
  }
})
const {
  executePayment: executePaymentMBWay,
} = useExecutePaymentMBWay({ idBooking: props.idBooking })

// redirected payment
const {
  executePayment: executePaymentRedirected,
} = useExecutePaymentRedirected({ idBooking: props.idBooking })

// Ideal payment
const {
  executePayment: executePaymentIdeal,
} = useExecutePaymentIDeal({ idBooking: props.idBooking })

// CB4X payment
const {
  executePayment: executePaymentCB4X,
} = useExecutePaymentCB4x({ idBooking: props.idBooking })

const onSubmit = async () => {
  const payment_type = bookingPaymentForm.value.selectedMethod
  const paymentMethod = bookingPaymentMethods.value?.find(method => method.payment_type === payment_type)

  if (!isDefined(paymentMethod) || !isDefined(payment_type)) {
    return
  }

  loadingModel.value = true
  uistate.value = STATES_UI_PAYMENT.PREPARATION
  try {
    await withErrorManagerHandling(
      () => checkoutStore.updateCheckoutInfos({
        id: props.idBooking,
        payload: { payments: paymentMethod.pk },
        sendPaymentsId: true,
      }),
      YSC_API_PAYMENT_SUMMARY_ERROR,
      true,
    )
  }
  catch (_e) {
    loadingModel.value = false
    return
  }

  uistate.value = STATES_UI_PAYMENT.EXECUTION

  switch (payment_type) {
    case BOOKING_PAYMENT_METHODS.CREDIT_CARD:
    case BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x: {
      try {
        const { confirmation } = await executePaymentCard({
          payment_type,
          form: bookingPaymentForm.value.forms[payment_type],
          paymentRedirectUrl: props.paymentCallbackHref,
        })
        transactionId.value = confirmation.transaction_id
      }
      catch (e) {
        await onExecutePaymentError(e)
        return
      }

      uistate.value = STATES_UI_PAYMENT.FINALIZATION
      break
    }

    case BOOKING_PAYMENT_METHODS.MBWAY: {
      uistate.value = STATES_UI_PAYMENT.MB_WAY_EXECUTION
      try {
        const { pay_in_id } = await executePaymentMBWay({
          payment_type,
          form: bookingPaymentForm.value.forms.mbway,
        })
        mbWayPaymentId.value = pay_in_id
      }
      catch (_e) {
        loadingModel.value = false
        await openErrorModal({ errorCode: PaymentErrorCategory.YSC_PAYMENTS })
        return
      }

      uistate.value = STATES_UI_PAYMENT.MB_WAY_FINALIZATION
      break
    }

    case BOOKING_PAYMENT_METHODS.KLARNA:
    case BOOKING_PAYMENT_METHODS.PAYPAL:{
      try {
        await executePaymentRedirected({
          payment_type,
          paymentRedirectUrl: props.paymentCallbackHref,
          backUrl: props.backUrlHref,
        })
      }
      catch (e) {
        await onExecutePaymentError(e)
        return
      }

      break
    }

    case BOOKING_PAYMENT_METHODS.IDEAL: {
      try {
        await executePaymentIdeal({
          payment_type,
          paymentRedirectUrl: props.paymentCallbackHref,
          form: bookingPaymentForm.value.forms.ideal,
        })
      }
      catch (e) {
        await onExecutePaymentError(e)
        return
      }

      uistate.value = STATES_UI_PAYMENT.FINALIZATION
      break
    }

    case BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x: {
      try {
        await executePaymentCB4X({
          payment_type,
          paymentRedirectUrl: props.paymentCallbackHref,
          backUrl: props.backUrlHref,
        })
      }
      catch (e) {
        await onExecutePaymentError(e)
        return
      }

      uistate.value = STATES_UI_PAYMENT.FINALIZATION
      break
    }

    default: {
      // Will have a type error if a supposedly supported payment type is not handled
      const _exhaustiveCheck: never = payment_type
      console.log(_exhaustiveCheck)
      break
    }
  }
}

// Payment processing Modal
const onPaymentProcessingError = async ({ errorCode }: { errorCode: string | number }) => {
  loadingModel.value = false
  await openErrorModal({ errorCode })
}

const onPaymentProcessingSuccess = () => {
  loadingModel.value = false
  emit('submitted', { transactionId: undefined })
}

const onPaymentProcessingAbort = () => {
  loadingModel.value = false
}

// coupon code
const bookingRequestStore = useBookingRequestStore()
const isCouponCodeValidated = computed(() => {
  if (!isDefined(bookingRequestStore.prices) || !('coupon_code' in bookingRequestStore.prices)) {
    return false
  }
  return isDefined(bookingRequestStore.prices.coupon_code)
})

const coupon_code = ref<string>()
if (isDefined(bookingRequestStore.prices) && 'coupon_code' in bookingRequestStore.prices) {
  coupon_code.value = bookingRequestStore.prices?.coupon_code
}

const loadingCoupon = ref<boolean>(false)
const onSubmitCoupon = async () => {
  loadingCoupon.value = true
  await bookingRequestStore.updateBookingRequestPrices({
    params: {
      coupon_code: coupon_code.value,
    },
  })
  loadingCoupon.value = false
}
</script>

<template>
  <div>
    <AppFunnelContentLayout
      :title="$t('pages.checkout.payment.title')"
      :loading="loadingModel"
    >
      <AppFormCouponCode
        v-model:coupon-code="coupon_code"
        :loading="loadingCoupon"
        :is-validated="isCouponCodeValidated"
        @submitted="onSubmitCoupon"
      />

      <AppLoading v-if="isPending" />
      <YscForm
        v-else
        :id="currentStep.idForm"
        class="flex flex-col items-stretch gap-4"
        :submit-action="onSubmit"
      >
        <AppPaymentOptionCard
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.CREDIT_CARD) && isDefined(bookingRequestStore.prices)"
          v-model="bookingPaymentForm.selectedMethod"
          v-model:form="bookingPaymentForm.forms.card"
          :id-booking="props.idBooking"
          :price-value="bookingRequestStore.prices.price.value"
          :currency="currencyMap[bookingRequestStore.prices.price.currency]"
        />

        <LazyAppPaymentOptionCard2x
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x)&& isDefined(bookingRequestStore.prices) "
          v-model="bookingPaymentForm.selectedMethod"
          v-model:form="bookingPaymentForm.forms.card_2"
          :id-booking="props.idBooking"
          :currency="currencyMap[bookingRequestStore.prices.price_2x_first.currency]"
        />

        <LazyAppPaymentOptionPaypal
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.PAYPAL) && flagPaymentPaypal"
          v-model="bookingPaymentForm.selectedMethod"
        />

        <LazyAppPaymentOptionCard4x
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x)"
          v-model="bookingPaymentForm.selectedMethod"
        />

        <LazyAppPaymentOptionKlarna
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.KLARNA)"
          v-model="bookingPaymentForm.selectedMethod"
        />

        <LazyAppPaymentOptionIdeal
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.IDEAL) && flagPaymentIdeal"
          v-model="bookingPaymentForm.selectedMethod"
          v-model:form="bookingPaymentForm.forms.ideal"
        />

        <LazyAppPaymentOptionMbWay
          v-if="bookingPaymentMethodsSet.has(BOOKING_PAYMENT_METHODS.MBWAY) && flagPaymentMbway"
          v-model="bookingPaymentForm.selectedMethod"
          v-model:form="bookingPaymentForm.forms.mbway"
        />
      </YscForm>
    </AppFunnelContentLayout>

    <Teleport to="body">
      <LazyYscModal
        v-if="safeRouteQueryErrorCode"
        @close="navigateTo({ query: omit('errorCode', $route.query) })"
      >
        <LazyAppPaymentErrors
          :error-code="safeRouteQueryErrorCode"
          @on-try-again="navigateTo({ query: omit('errorCode', $route.query) })"
          @on-back-to-booking="navigateTo({ name: 'd-trips-id', params: { id: idBooking } })"
        />
      </LazyYscModal>

      <LazyYscModal
        v-else-if="loadingModel"
        :can-close="false"
      >
        <template
          v-if="mbWayProcessingTitle"
          #title
        >
          {{ mbWayProcessingTitle }}
        </template>

        <AppPaymentProcessing
          v-model:state="uistate"
          :id-booking="idBooking"
          :transaction-id="transactionId"
          :mb-way-payment-id="mbWayPaymentId"
          :phone="bookingPaymentForm.forms.mbway.phone"
          @error="onPaymentProcessingError"
          @success="onPaymentProcessingSuccess"
          @abort="onPaymentProcessingAbort"
        />
      </LazyYscModal>
    </Teleport>
  </div>
</template>
