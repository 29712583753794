<script setup lang="ts">
const {
  loading,
  currentStep,
  onStepSuccess,
} = useAppFunnelInject()

const bookingGuestStore = useBookingsGuestStore()
const { origin } = useRequestURL()
const paymentCallbackHref = computed(() => {
  const url = new URL('/api/internals/booking-payment-callback', origin)
  url.searchParams.append('booking_type', 'instant')
  url.searchParams.append('id_camper', `${bookingGuestStore.bookingGuest?.camper.id}`)
  url.searchParams.append('id_booking', `${bookingGuestStore.bookingGuest?.id}`)

  return url.href
})

const route = useRoute()
const onSubmitted = ({ transactionId }: { transactionId?: number }) => {
  if (transactionId) {
    onStepSuccess({ query: { transactionId } })
    return
  }

  return navigateTo({ name: 'campers-id-instant-request-request_id-success',
    params: route.params,
    query: route.query,
  })
}
</script>

<template>
  <div>
    <AppFunnelStepBookingRequestPayment
      v-if="isDefined(currentStep) && isDefined(bookingGuestStore.bookingGuest)"
      v-model:loading="loading"
      :current-step="currentStep"
      :id-booking="bookingGuestStore.bookingGuest.id"
      :payment-callback-href="paymentCallbackHref"
      :back-url-href="$route.fullPath"
      @submitted="onSubmitted"
    />
  </div>
</template>
